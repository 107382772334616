@import "reset.css";

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-SemiBold';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-Bold';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Medium';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-ExtraBold';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@import url('//fonts.googleapis.com/earlyaccess/notosanskr.css');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100..900&display=swap');

html, body {
  font-family: 'Pretendard-SemiBold';
  /* overflow: scroll; */
  overflow-x: hidden;
  background-color: #FFFBFD;
}

/* dark mode support */
@media (prefers-color-scheme: dark) {
  html, body {
    background-color: #1A1A1A;
    color: white;
  }
}

input, textarea, select {
  border: .9px solid #BEBEBE;
  border-radius: 0.63rem;
  padding: 0.65rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  outline: none;
  resize: none;
  font-family: 'Pretendard-Regular';
  font-size: 0.83rem;
  box-sizing: border-box;
  background-color: white;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: black;
}

@media (prefers-color-scheme: dark) {
  input, textarea, select {
    background-color: #383838;
    color: white;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
@media (prefers-color-scheme: dark) {
  .App-header {
    background-color: #D3D3D3;
    color: black;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}