.upload-outer-box {
    background-color: #FFFBFD;

    width: 100vw;
    height: calc(100vh - 4.5rem);

    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
}
@media (prefers-color-scheme: dark) {
    .upload-outer-box {
        background-color: #333333;
    }
}

.upload-inner-box {
    border: .9px solid #BEBEBE;
    border-radius: 0.89rem;
    padding: 1rem;
    box-sizing: border-box;

    width: calc(100% - 5rem);
    height: calc(100% - 5rem);

    background-color: #FFFDFE;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media (prefers-color-scheme: dark) {
    .upload-inner-box {
        background-color: #333333;
        border: .9px solid #555555;
    }
}

.upload-inner-box > .title {
    font-size: 2.3rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    color: #333333;
    font-family: 'Pretendard-SemiBold';

    margin-bottom: 3rem;
}
@media (prefers-color-scheme: dark) {
    .upload-inner-box > .title {
        color: white;
    }
}

.upload-inner-box > .title > img {
    height: 4rem;
    margin-right: 0.56rem;
    transform: translateY(-0.1rem);
}

.upload-inner-box .image {
    width: 10rem;
    margin-top: 0.5rem;

    border-radius: .9rem;
    border: .9px solid #BEBEBE;
}
@media (prefers-color-scheme: dark) {
    .upload-inner-box .image {
        border: .9px solid #555555;
    }
}

.upload-inner-box > .contents {
    display: flex;
    flex-direction: row;

    margin-bottom: 2rem;
}

.upload-inner-box > .contents > .contents-left {
    margin-right: 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-inner-box > .contents > .contents-left > div {
    font-size: 0.65rem;
    color: #DD0D75;
    margin-top: 0.2rem;
}

.upload-inner-box > .contents > .contents-right {
    display: flex;
    flex-direction: column;
    height: 10.5rem;
}

.upload-inner-box > .contents > .contents-right .explain-box {
    flex-grow: 1;
    margin-top: 0.5rem;
}

.upload-inner-box > .contents > .contents-right .explain {
    flex-grow: 1;
    justify-content: start;
}

.upload-inner-box > .contents > .contents-right input {
    width: 13rem;
}
.upload-inner-box > .contents > .contents-right select {
    width: 13rem;
}
.upload-inner-box > .contents > .contents-right textarea {
    width: 13rem;
}

.upload-outer-box > .cropview {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    /* background-color: #00000077; */

    /* From https://css.glass */
    /* background: rgba(255, 255, 255, 0.1); */
    background: #FFEBF530;
    /* box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(15.5px);
    -webkit-backdrop-filter: blur(15.5px);
}
@media (prefers-color-scheme: dark) {
    .upload-outer-box > .cropview {
        background: #33333377;
    }
}

.upload-inner-box > form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.upload-inner-box > form > button {
    margin-top: 2rem;
}

@media (max-width: 865px) {
    .upload-inner-box > .title {
        font-size: 1.9rem;
    
        margin-bottom: 1.9rem;
    }
    .upload-inner-box > .title > img {
        height: calc(4rem * 1.9 / 2.3);
        margin-right: calc(0.56rem * 1.9 / 2.3);
        transform: translateY(-0.1rem);
    }
    .upload-inner-box > .contents > .contents-left {
        margin-right: 2rem;
    }
    .upload-inner-box > .contents > .contents-right input {
        width: 11rem;
    }
    .upload-inner-box > .contents > .contents-right select {
        width: 11rem;
    }
    .upload-inner-box > .contents > .contents-right textarea {
        width: 11rem;
    }
    .upload-inner-box > .contents {
        margin-bottom: 1.5rem;
    }
    .upload-inner-box > form > button {
        margin-top: 1.7rem;
    }
}

@media (max-width: 520px) {
    .upload-outer-box {
        box-sizing: border-box;
        min-height: calc(100vh - 4.5rem);
        height: auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
        /* padding-top: 1rem;
        padding-bottom: 1rem; */
        position: absolute;
    }
    .upload-inner-box > .title {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
    .upload-inner-box > .title > img {
        height: calc(4rem * 1.5 / 2.3);
        margin-right: calc(0.56rem * 1.5 / 2.3);
        transform: translateY(-0.1rem);
    }
    .upload-inner-box {
        width: calc(100% - 2rem);
        height: auto; /*calc(100% - 2rem); */
        /* padding: 0.5rem; */
        padding-top: 3rem;
        padding-bottom: 2rem;

        /* position: relative;
        top: 1rem;
        bottom: 1rem;
        left: 1rem;
        right: 1rem; */
    }
    .upload-inner-box .image {
        width: 47vw;
        margin-top: 0.3rem;
    }
    .upload-inner-box > .contents {
        display: flex;
        flex-direction: column;
    
        margin-bottom: 0.5rem;

        justify-content: center;
    }
    .upload-inner-box > .contents > .contents-left {
        margin-right: 0;
    }
    .upload-inner-box > .contents > .contents-right input {
        box-sizing: border-box;
        width: 47vw;
        font-size: 0.8rem;
        padding: 0.8rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .upload-inner-box > .contents > .contents-right select {
        box-sizing: border-box;
        width: 47vw;
        font-size: 0.8rem;
        padding: 0.8rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .upload-inner-box > .contents > .contents-right textarea {
        box-sizing: border-box;
        width: 47vw;
        font-size: 0.8rem;
        padding: 0.8rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .upload-inner-box > form {
        transform: scale(0.85);
    }
    .upload-inner-box > form > button {
        margin-top: 1rem;
    }
}
@media (max-width: 340px) {
    .upload-inner-box {
        width: calc(100% - 1.5rem);
        height: calc(100% - 1.5rem);
        padding: 0.5rem;
    }
    .upload-inner-box .image {
        width: 55vw;
    }
    .upload-inner-box > .contents > .contents-right input {
        width: 55vw;
    }
    .upload-inner-box > .contents > .contents-right textarea {
        width: 55vw;
    }
    .upload-inner-box > form {
        transform: scale(0.65);
    }
}