.login-div {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    background-color: #FFFBFD;
}

@media (prefers-color-scheme: dark) {
    .login-div {
        background-color: #383838;
    }
}

.login-div > .logo { 
    width: 55vw;

    margin-bottom: 40vh;
}
@media (max-width: 768px) {
    .login-div > .logo {
        width: 70vw;
    }
}
@media (max-width: 568px) {
    .login-div > .logo {
        width: 80vw;
    }
}
@media (max-width: 468px) {
    .login-div > .logo {
        width: 85vw;
    }
}
@media (max-width: 368px) {
    .login-div > .logo {
        width: 90vw;
    }
}