.titlebox {
    display: flex;
    flex-direction: column;

    width: 100%;
}

.titlebox > .title {
    font-size: 1.048rem;
    margin: 0.5rem;
    margin-top: .5rem;
    margin-left: 0.45rem;
    margin-bottom: .6rem;
    font-family: 'Pretendard-Bold';
    color: #333333;
}

.titlebox > .mainpage-title { /* 메인페이지 한정 적용할 타이틀 스타일 */
    font-size: 1.46rem;
    margin: 0.5rem;
    margin-top: 2.15rem;
    margin-left: 1.32rem;
    margin-bottom: 1.2rem;
    font-family: 'Pretendard-Bold';
    color: #333333;
}

@media (prefers-color-scheme: dark) {
    .titlebox > .title {
        color: #FFFDFE;
    }
    .titlebox > .mainpage-title {
        color: #FFFDFE;
    }
}