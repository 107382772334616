@keyframes cursor-blink {
    0% {
        opacity: 0;
    }
}
.cursor-box {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
}
.cursor-box > .cursor {
    height: 1em;
    width: 1px;
    margin-left: 0.1rem;
    background-color: white;

    animation: cursor-blink 1s steps(2) infinite;
}

@keyframes move-left {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

@keyframes move-left-1 {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    49.9% {
        transform: translateX(-100%);
        opacity: 1;
    }
    49.91% {
        opacity: 0;
    }
    49.92% {
        opacity: 0;
        transform: translateX(100%);
    }
    50% {
        transform: translateX(100%);
        opacity: 1;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes move-left-2 {
    0% {
        opacity: 1;
        transform: translateX(100%);
    }
    99.9% {
        transform: translateX(-100%);
        opacity: 1;
    }
    99.91% {
        opacity: 0;
    }
    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}

.introduce-box {
    --dimi-magenta: #DD0D75;
    --dark-gray: #333333;
    --light-dimi-magenta: #E0BFD0;
    --lighter-dimi-magenta: #F3D8E5;
    --semi-light-dimi-magenta: #D9A2BE;
    --gray: #86868B;

    background-color: #121212;

    color: white;

    --margin: calc((100% - 70rem) / 2);
    --inner-margin: calc((100% - 65rem) / 2);

    overflow: hidden;
}
.introduce-box-ja {
    font-family: 'Noto Sans JP';
    font-weight: 600;
    letter-spacing: -0.025em;
}
.introduce-box-zh {
    font-family: 'Noto Sans SC';
    font-weight: 600;
    letter-spacing: -0.02em;
}

@media (max-width: 1200px) {
    .introduce-box {
        --margin: calc((100% - 57rem) / 2);
        --inner-margin: calc((100% - 53rem) / 2);
    }
}
@media (max-width: 950px) {
    .introduce-box {
        --margin: calc((100% - 43rem) / 2);
        --inner-margin: calc((100% - 40rem) / 2);
    }
}
@media (max-width: 730px) {
    .introduce-box {
        --margin: calc((100% - 33rem) / 2);
        --inner-margin: calc((100% - 30rem) / 2);
    }
}
@media (max-width: 580px) {
    .introduce-box {
        --margin: 1rem;
        --inner-margin: 2rem;
    }
}

.introduce-box .logo {
    height: 2rem;
}

.placeholder {
    flex-grow: 1;
}

.introduce-box button, .introduce-box a {
    border: 1px solid var(--dark-gray);
    background-color: var(--dimi-magenta);
    border-radius: 0.6rem;

    box-sizing: border-box;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    text-decoration: none;
    color: white;
    font-size: 0.8rem;
}
.introduce-box h1 {
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text; /* for chrome */
    background-image: linear-gradient(180deg,white,var(--lighter-dimi-magenta));

    font-size: 4.5rem;
    font-family: 'Pretendard-ExtraBold';
    line-height: 5rem;

    position: relative;
    z-index: 1;
}
.introduce-box-zh h1 {
    font-family: 'Noto Sans SC';
    font-weight: 500;
    /* font-size: 4.35rem; */
}
.introduce-box-ja h1 {
    font-family: 'Noto Sans JP';
    font-weight: 500;
    /* font-size: 4.35rem; */
    letter-spacing: -0.055em;
}
.introduce-box-zh h2 {
    font-size: 2rem;
    font-family: 'Noto Sans SC';
    line-height: 2.4rem;

    color: var(--gray);
}
.introduce-box h2 {
    font-size: 2rem;
    font-family: 'Pretendard-ExtraBold';
    line-height: 2.4rem;

    color: var(--gray);
}
.introduce-box em {
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text; /* for chrome */
    background-image: linear-gradient(180deg,white,var(--lighter-dimi-magenta));
}
.introduce-box h3 {
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text; /* for chrome */
    background-image: linear-gradient(180deg,var(--light-dimi-magenta),var(--semi-light-dimi-magenta));

    font-size: 1rem;
    font-family: 'Pretendard-SemiBold';
    line-height: 1.2rem;
}
.introduce-box select {
    background-color: transparent;
    color: white;
    border: none;
}

.introduce-box > .introduce-header {
    display: flex;
    flex-direction: row;
    
    margin-left: var(--margin);
    margin-right: var(--margin);

    align-items: center;

    box-sizing: border-box;
    height: 4rem;
    padding-top: 1rem;
}

.introduce-box > .content-1 {
    box-sizing: border-box;
    height: calc(100vh - 4rem);

    padding-top: 23vh;
    padding-left: var(--inner-margin);
    padding-right: var(--inner-margin);

    display: flex;
    flex-direction: column;
}

.introduce-box .subject-box {
    position: relative;
}

.introduce-box .underline {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    position: absolute;
    bottom: 0;
    left: 12.5rem;
}
.introduce-box .underline > div {
    width: 17rem;
    height: 1rem;
    background-color: var(--dimi-magenta);
    transform: rotate(-1deg); /* 361도 */
}
.introduce-box .underline-zh {
    left: 0rem;
}
.introduce-box .underline-zh > div {
    width: 22.4rem;
}
.introduce-box .underline-ja {
    left: 12.8rem;
}
.introduce-box .underline-ja > div {
    width: 16.9rem;
}

.introduce-box-en h1 {
    font-size: 3.8rem;
    line-height: 4.3rem;
}
.introduce-box .underline-en {
    display: none; /* 이건 임시로 해놓은 거 ,, */
}

.introduce-box .underline > .dimibug {
    height: 1.9rem;
}

.introduce-box .down-arrow {
    margin-bottom: 1rem;
}

@media (max-width: 770px) {
    .introduce-box h1 {
        font-size: 4rem;
        line-height: 4.5rem;
    }
    .introduce-box .underline {
        left: 11.3rem;
    }
    .introduce-box .underline > div {
        width: 15rem;
    }
    .introduce-box .underline > .dimibug {
        height: 1.8rem;
    }
    .introduce-box .underline-zh {
        left: 0rem;
    }
    .introduce-box .underline-zh > div {
        width: 19.9rem;
    }
    .introduce-box .underline-ja {
        left: 11.4rem;
    }
    .introduce-box .underline-ja > div {
        width: 14.9rem;
    }
}
@media (max-width: 660px) {
    .introduce-box h1 {
        font-size: 3.5rem;
        line-height: 4rem;
    }
    .introduce-box .underline {
        left: 9.7rem;
    }
    .introduce-box .underline > div {
        width: 13.3rem;
        height: 0.8rem;
    }
    .introduce-box .underline > .dimibug {
        height: 1.6rem;
    }
    .introduce-box .underline-zh {
        left: 0rem;
    }
    .introduce-box .underline-zh > div {
        width: 17.4rem;
    }
    .introduce-box .underline-ja {
        left: 10rem;
    }
    .introduce-box .underline-ja > div {
        width: 13rem;
    }
}
@media (max-width: 490px) {
    .introduce-box h1 {
        font-size: 3rem;
        line-height: 3.5rem;
    }
    .introduce-box .underline {
        left: 8.3rem;
    }
    .introduce-box .underline > div {
        width: 11.6rem;
        height: 0.7rem;
    }
    .introduce-box .underline > .dimibug {
        height: 1.4rem;
    }
    .introduce-box .underline-zh {
        left: 0rem;
    }
    .introduce-box .underline-zh > div {
        width: 14.9rem;
    }
    .introduce-box .underline-ja {
        left: 8.5rem;
    }
    .introduce-box .underline-ja > div {
        width: 11.2rem;
    }
}
@media (max-width: 420px) {
    .introduce-box h1 {
        font-size: 2.3rem;
        line-height: 2.7rem;
    }
    .introduce-box .underline {
        left: 6.4rem;
    }
    .introduce-box .underline > div {
        width: 8.6rem;
        height: 0.6rem;
    }
    .introduce-box .underline > .dimibug {
        height: 1.2rem;
    }
    .introduce-box .underline-zh {
        left: 0rem;
    }
    .introduce-box .underline-zh > div {
        width: 11.5rem;
    }
    .introduce-box .underline-ja {
        left: 6.4rem;
    }
    .introduce-box .underline-ja > div {
        width: 8.8rem;
    }
}



.introduce-box > .content-2 {
    box-sizing: border-box;
    height: calc(100vh);
    overflow-x: hidden;

    padding-top: 11vh;
    /* padding-left: var(--margin);
    padding-right: var(--margin); */

    display: flex;
    flex-direction: column;

    overflow-y: hidden;
}
.introduce-box > .content-2 > h2 {
    padding-left: var(--margin);
    margin-bottom: 13vh;
}

.introduce-box > .content-2 .monsters {
    animation: move-left 35s linear infinite;

    display: flex;
    flex-direction: row;
    width: fit-content;
}

.introduce-box > .content-2 .monsters-image {
    width: 140vw;

    /* animation: move-left-1 35s linear infinite; */
    /* position: absolute; */
    padding-left: 0.8vw;
    padding-right: 0.8vw;
}
.introduce-box > .content-2 .monsters-image-2 {
    width: 140vw;

    /* animation: move-left-2 35s linear infinite; */
    /* position: absolute; */
    /* left: 100%; */
    padding-left: 0.8vw;
    padding-right: 0.8vw;
}

@media (max-width: 950px) {
    .introduce-box > .content-2 .monsters-image {
        width: 170vw;
    
        padding-left: 1.05vw;
        padding-right: 1.05vw;
    }
    .introduce-box > .content-2 .monsters-image-2 {
        width: 170vw;
    
        padding-left: 1.05vw;
        padding-right: 1.05vw;
    }
}
@media (max-width: 730px) {
    .introduce-box > .content-2 .monsters-image {
        width: 200vw;
    
        padding-left: 1.1vw;
        padding-right: 1.1vw;
    }
    .introduce-box > .content-2 .monsters-image-2 {
        width: 200vw;
    
        padding-left: 1.1vw;
        padding-right: 1.1vw;
    }
}
@media (max-width: 520px) {
    .introduce-box > .content-2 .monsters-image {
        width: 310vw;
    
        padding-left: 1.4vw;
        padding-right: 1.4vw;
    }
    .introduce-box > .content-2 .monsters-image-2 {
        width: 310vw;
    
        padding-left: 1.4vw;
        padding-right: 1.4vw;
    }
}




.introduce-box > .content-3 {
    box-sizing: border-box;
    height: calc(100vh);

    display: flex;
    flex-direction: column;

    padding-top: 10vh;
}

.introduce-box > .content-3 > .yourturn {
    text-align: center;
    font-size: 3rem;
    font-family: 'Pretendard-Bold';
    line-height: 3.6rem;
}
.introduce-box-zh > .content-3 > .yourturn {
    font-family: 'Noto Sans SC';
}
.introduce-box-ja > .content-3 > .yourturn {
    font-family: 'Noto Sans JP';
}

.introduce-box > .content-3 > .life-sharing {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;

    margin-left: var(--inner-margin);
    margin-right: var(--inner-margin);
    
    align-items: center;

    margin-top: 20vh;
    margin-bottom: 23vh;

    height: 5rem;
}

.introduce-box > .content-3 > .life-sharing > :nth-child(1) {
    text-align: left;

    font-size: 1.5rem;
}
.introduce-box > .content-3 > .life-sharing > :nth-child(2) {
    text-align: center;
}
.introduce-box > .content-3 > .life-sharing > :nth-child(3) {
    text-align: right;
}
.introduce-box > .content-3 > .life-sharing > :nth-child(3) > img {
    height: 2.8rem;
}

.introduce-box > .content-3 .start {
    display: flex;
    flex-direction: column;
    align-items: center;
}


@media (max-width: 600px) {
    .introduce-box > .content-3 > .yourturn {
        font-size: 2rem;
        line-height: 2.3rem;
    }
    .introduce-box > .content-3 > .life-sharing {
        grid-template-columns: 2fr 3fr 2fr;
    }
    .introduce-box > .content-3 > .life-sharing > :nth-child(1) {
        font-size: 1.2rem;
    }
    .introduce-box > .content-3 > .life-sharing > :nth-child(3) > img {
        height: 2.2rem;
    }
}