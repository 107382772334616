.notfound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFBFD;
    height: 100vh;
}

.notfound > .notfound-image {
    max-width: 55vw;
    max-height: 55vh;
    object-fit: contain;
}

.notfound > .logo-image {
    max-width: 55vw;
    max-height: 10vh;
    object-fit: contain;
    transform: translateY(-1.5rem);
}

.notfound > div {
    margin-top: 0.5rem;
    font-size: 1.5rem;
    color: #444444;
}
@media (prefers-color-scheme: dark) {
    .notfound {
        background-color: #333333;
    }

    .notfound > div {
        color: white;
    }
}