.imageview {
    border: .9px solid #BEBEBE;

    border-radius: 1.3rem;
    padding: 1.23rem;

    display: flex;
    flex-direction: row;

    position: relative;
    background: #FFFDFE;
}
@media (prefers-color-scheme: dark) {
    .imageview {
        background: #2c2c2c;
        border: .9px solid #444444;
    }
}

.imageview > .thumbnail > img {
    width: 7.6rem;
    height: 7.6rem;

    min-width: 6.5rem;

    border-radius: .6rem;

    /* border: .9px solid #BEBEBE; */
    margin-right: 1.346rem;
    margin-left: .25rem;
    margin-top: .25rem;
    margin-bottom: .25rem;

    border: .7px solid #BEBEBE;


    /* 사진크기 */
}
@media (prefers-color-scheme: dark) {
    .imageview > .thumbnail > img {
        border: .7px solid #444444;
    }
}

.imageview > .rightbox {
    display: flex;
    flex-direction: column;

    width: 100%;
}

.imageview > div > .title {
    color: #DD0D75;
    font-size: 1.47rem;
    font-family: 'Pretendard-Bold';
    
    margin-top: .312rem;
    margin-bottom: 0.22rem;

    /* 제목 */
}

.imageview > div > .content {
    margin-top: 0.03rem;
    margin-top: 0.03rem;
    color: #929292;
    margin-left: .1rem;
    margin-top: .1rem;
    font-family: 'Pretendard-Medium';
    font-size: 0.76rem;
}
@media (prefers-color-scheme: dark) {
    .imageview > div > .content {
        color: #BEBEBE;
    }
}

.imageview > div > .margin {
    flex-grow: 1;
}

.imageview > div > button {
    background-color: #DD0D75;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.795rem;
    border: .5px solid #BEBEBE;
    color: white;
    margin-bottom: .3rem;
}
@media (prefers-color-scheme: dark) {
    .imageview > div > button {
        border: .5px solid #444444;
    }
}

.imageview > div > .disabled {
    background-color: #F9F9F9;
    color: #333333;
    /* cursor: default; unlike도 누를수 있어야 함*/
}
@media (prefers-color-scheme: dark) {
    .imageview > div > .disabled {
        background-color: #333333;
        color: #BEBEBE;
    }
}

.imageview > .floatbox {
    padding: 0.5rem;
    background-color: #FFFDFE;

    position: absolute;

    bottom: 1.1rem;
    left: 1.1rem;

    display: flex;
    flex-direction: row;
    font-size: 0.8rem;

    border: .6px solid #BEBEBE;
    border-radius: 0.5rem;

    /* 하트박스 */
}
@media (prefers-color-scheme: dark) {
    .imageview > .floatbox {
        background-color: #333333;
        border: .6px solid #444444;
    }
}

.imageview > .floatbox > img {
    height: 0.75rem;
    color: #333333;
    margin-left: .13rem;
    margin-right: .065rem;
}

.imageview > .floatbox > .hearts {
    margin-right: .13rem;
}