@keyframes move {
    /* keyframe */
    from {
        left: 0;
    }
    /* keyframe */
    to {
        left: 100%;
    }
}
/* 테스트 중 ... */
.animated {
    position: relative;
    white-space: nowrap;
    /* max-width: 200px; */
    overflow: hidden;
    /* background: #0c0c0c; */
    display: inline-block;
    width: fit-content;
    position: relative;
}

.text-animated {
    /* color: #fff; */
    animation: backAndForth 6s linear infinite;
    display: inline-block;
    position: relative;
    /* min-width: 100%; */

    /* -webkit-backface-visibility: hidden;
    will-change: transform; */
}

@keyframes backAndForth {
    0% {
        transform: translateX(0);
        -webkit-transform: translate3d(0, 0, 0);
        left: 0;
    }
    16% {
        transform: translateX(0);
        -webkit-transform: translate3d(0, 0, 0);
        left: 0;
    }
    49% {
        transform: translateX(calc(-100%));
        -webkit-transform: translate3d(calc(-100%), 0, 0);
        /* left: 100%; */
    }
    49.5% {
        transform: translate(calc(-100%), calc(100%));
    }
    50.5% {
        transform: translate(calc(100%), calc(100%));
    }
    51% {
        transform: translateX(calc(100%));
        -webkit-transform: translate3d(calc(100%), 0, 0);
        /* left: 100%; */
    }
    84% {
        transform: translateX(0);
        -webkit-transform: translate3d(0, 0, 0);
        left: 0;
    }
    100% {
        transform: translateX(0);
        -webkit-transform: translate3d(0, 0, 0);
        left: 0;
    }
}