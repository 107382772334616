.imageview-big {
    border: .9px solid #BEBEBE;

    border-radius: 1.6rem;
    padding: 1.8rem;

    display: flex;
    flex-direction: row;

    position: relative;
    background: #FFFDFE;
}
@media (prefers-color-scheme: dark) {
    .imageview-big {
        border: .9px solid #444444;
        background: #2c2c2c;
    }
}

.imageview-big > .thumbnail > img {
    width: 10rem;
    height: 10rem;

    min-width: 6.5rem; /* 이거 왜있는거지? */

    border-radius: .7rem;

    margin-right: 1.38rem;
    margin-left: .05rem;
    margin-top: .05rem;
    margin-bottom: .05rem;

    border: .7px solid #BEBEBE;

    /* 사진크기 */
}
@media (prefers-color-scheme: dark) {
    .imageview-big > .thumbnail > img {
        border: .8px solid #444444;
    }
}


.imageview-big > .rightbox {
    display: flex;
    flex-direction: column;

    width: 100%;
}

.imageview-big > div > .title {
    color: #DD0D75;
    font-size: 1.47rem;
    font-family: 'Pretendard-Bold';
    
    margin-top: .312rem;
    margin-bottom: 0.22rem;

    /* 제목 */
}

.imageview-big > div > .content {
    margin-top: 0.03rem;
    margin-top: 0.03rem;
    color: #929292;
    margin-left: .1rem;
    margin-top: .1rem;
    font-family: 'Pretendard-Medium';
    font-size: 0.76rem;
}
@media (prefers-color-scheme: dark) {
    .imageview-big > div > .content {
        color: #BEBEBE;
    }
}

.imageview-big > div > .margin {
    flex-grow: 1;
}

.imageview-big > div > button {
    background-color: #DD0D75;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.7951rem;
    border: .5px solid #BEBEBE;
    color: white;
    margin-bottom: .3rem;
}
@media (prefers-color-scheme: dark) {
    .imageview-big > div > button {
        background-color: #DD0D75;
        border: .5px solid #555555;
    }
}

.imageview-big > div > .disabled {
    background-color: #F9F9F9;
    color: #333333;
    /* cursor: default; unlike도 누를수 있어야 함*/
}
@media (prefers-color-scheme: dark) {
    .imageview-big > div > .disabled {
        background-color: #333333;
        color: #BEBEBE;
    }
}

.imageview-big > .floatbox {
    padding: 0.5rem;
    background-color: #FFFDFE;

    position: absolute;

    bottom: 1.4rem;
    left: 1.4rem;

    display: flex;
    flex-direction: row;
    font-size: 0.8rem;

    border: .6px solid #BEBEBE;
    border-radius: 0.5rem;

    /* 하트박스 */
}
@media (prefers-color-scheme: dark) {
    .imageview-big > .floatbox {
        background-color: #333333;
        border: .6px solid #555555;
    }
}

.imageview-big > .floatbox > img {
    height: 0.75rem;
    color: #333333;
    margin-left: .13rem;
    margin-right: .065rem;
}
@media (prefers-color-scheme: dark) {
    .imageview-big > .floatbox > img {
        color: #BEBEBE;
    }
}

.imageview-big > .floatbox > .hearts {
    margin-right: .13rem;
}