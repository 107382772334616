.bottom-bar-component {
    --width: min(80vw, 70vh);
    --width-1percent: calc(var(--width) / 100);

    font-size: calc(var(--width-1percent) * 3.5);

    height: 12%;
    /* color: rgb(156, 154, 154); */
    color: #333333;

    position: absolute;
    bottom: -15%; /* 13% + 6% */
    left: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    align-items: center;

    border: .9px solid #BEBEBE;
    border-radius: calc(var(--width-1percent) * 3.3);
    background-color: #FFFDFE;

    font-family: 'Pretendard-SemiBold';

    /* word-break: keep-all; */
}
@media (prefers-color-scheme: dark) {
    .bottom-bar-component {
        color: white;
        background-color: #333333;
    }
}
.bottom-bar-component > .title-contents-box {
    flex-grow: 1;
    margin-right: 2%;

    width: calc(95% - var(--width-1percent) * 20);

    box-sizing: border-box;
    padding-left: calc(var(--width-1percent) * 2);
    padding-right: calc(var(--width-1percent) * 0.6);

    white-space: nowrap;

    display: flex;
    flex-direction: row;
    align-items: center;

    overflow: hidden;
}

.bottom-bar-component > .title-contents-box > .content {
    /* display: flex;
    flex-direction: row;
    align-items: center; */

    overflow: hidden;

    width: 100%;
}

.bottom-bar-component > .content {
    height: 100%;
    font-family: 'Pretendard-Bold';
}
.bottom-bar-component > .like {
    border: .9px solid #BEBEBE;
    border-radius: calc(var(--width-1percent) * 2);
    background-color: #FFFDFE;

    font-family: 'Pretendard-Bold';
}
@media (prefers-color-scheme: dark) {
    .bottom-bar-component {
        border: .9px solid #444444;
        background-color: #333333;
    }
    .bottom-bar-component > .like {
        border: .9px solid #444444;
        background-color: #333333;
    }
}

.bottom-bar-component > div > .title {
    color: #DD0D75;

    margin-left: 2.7%;
}

.bottom-bar-component > div > .content {
    flex-grow: 1;
    margin-left: calc(var(--width-1percent) * 2);
}

.bottom-bar-component > .like {
    height: calc(100% - var(--width-1percent) * 5.6);
    width: calc(var(--width-1percent) * 14);
    flex-shrink: 0;
    margin-right: calc(var(--width-1percent) * 3);
    font-size: 87%;
    box-sizing: border-box;
    
    padding: calc(var(--width-1percent) * 2);
    background-color: #FFFDFE;

    font-family: "Pretendard-SemiBold";

    display: flex;
    flex-direction: row;
    /* font-size: 0.8rem; */

    align-items: center;
    justify-content: center;
}
@media (prefers-color-scheme: dark) {
    .bottom-bar-component > .like {
        background-color: #333333;
    }
}

.bottom-bar-component > .like > img {
    height: calc(var(--width-1percent) * 2.7);
    color: #333333;

    margin-right: calc(var(--width-1percent) * 1);
}

@media (prefers-color-scheme: dark) {
    .bottom-bar-component > .like > img {
        color: white;
    }
}

@media (max-width: 570px) {
    .bottom-bar-component {
        font-size: calc(var(--width-1percent) * 4.5);
        height: 14%;
        bottom: -17%;
        border-radius: calc(var(--width-1percent) * 3.9);
    }
    .bottom-bar-component > .like {
        height: calc(100% - var(--width-1percent) * 5.5);
        width: calc(var(--width-1percent) * 17);
    }
    .bottom-bar-component > .like > img {
        height: calc(var(--width-1percent) * 3.7);
        margin-right: calc(var(--width-1percent) * 0.9);
    }
}

@media (max-width: 400px) {
    .bottom-bar-component {
        font-size: calc(var(--width-1percent) * 5.5);
        height: 15%;
        bottom: -18%;
        border-radius: calc(var(--width-1percent) * 4.1);
    }
    .bottom-bar-component > .like {
        height: calc(100% - var(--width-1percent) * 5.1);
        width: calc(var(--width-1percent) * 19);
    }
    .bottom-bar-component > .like > img {
        height: calc(var(--width-1percent) * 4.2);
        margin-right: calc(var(--width-1percent) * 0.8);
    }
}