.crop-box {
    position: relative;
    width: min(80vw, 70vh);
    height: min(80vw, 70vh);
    background-color: white;

    margin-bottom: 7%;
}

/* @media (max-width: 865px) {
    .crop-box {
        width: 65vw;
        height: 65vw;
    }
}

@media (max-width: 520px) {
    .crop-box {
        width: 75vw;
        height: 75vw;
    }
}

@media (max-width: 340px) {
    .crop-box {
        width: 80vw;
        height: 80vw;
    }
} */