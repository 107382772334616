.about-div {
    background-color: #FFFBFD;
    min-height: calc(100vh - 4.5rem);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (prefers-color-scheme: dark) {
    .about-div {
        background-color: #333333;
    }
}

.about-div > .logo2 {
    max-height: 30vh;
    max-width: 50vw;
}

.about-div > .logo {
    max-height: 20vh;
    max-width: 35vw;
}

.about-div > .privacy {
    margin-top: 1.6rem;
    display: flex;
    flex-direction: row;
}
.about-div > .privacy > a {
    margin-left: 0.35rem;
    margin-right: 0.35rem;
    font-size: 1.1rem;
    text-decoration: underline;
}

.about-div > .introduce {
    font-size: 1.5rem;
    margin-top: 4rem;

    color: #444444;
    text-align: center;
}

@media (prefers-color-scheme: dark) {
    .about-div > .introduce {
        color: white;
    }
}

@media (max-width: 1000px) {
    .about-div > .logo {
        max-width: 50vw;
    }
    .about-div > .introduce {
        font-size: 1.3rem;
    }
}

@media (max-width: 768px) {
    .about-div > .logo2 {
        max-height: 30vh;
        max-width: 50vw;
    }
    .about-div > .logo {
        max-height: 20vh;
        max-width: 60vw;
    }
    .about-div > .introduce {
        font-size: 1.2rem;
    }
}

@media (max-width: 500px) {
    .about-div > .logo2 {
        max-width: 40vw;
        margin-bottom: 1rem;
    }
    .about-div > .logo {
        max-width: 80vw;
    }
    .about-div > .introduce {
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin-top: 2rem;
    }
}

.about-div a {
    color: #DD0D75;
}