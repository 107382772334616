.mainpage {
    width: 100vw;
    /* min-height: calc(100vh - 4.5rem); */

    background-color: #FFFBFD;

    box-sizing: border-box;
    padding: 1.2rem;
    padding-top: 0;

    /* height: 100%;
    overflow-y: scroll; */

    padding-left: calc((100% - 66.3rem) / 2);
    padding-right: calc((100% - 66.3rem) / 2);

    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (prefers-color-scheme: dark) {
    .mainpage {
        background-color: #333333;
    }
}

/* .mainpage > .contents {
    box-sizing: border-box;

    padding: 1rem;
} */

.mainpage .weekly {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.98rem;
}

.mainpage .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, minmax(100px, auto));

    row-gap: 1.66rem;
    column-gap: 1.66rem;
}

@media (max-width: 1200px) {
    .mainpage .gallery {
        grid-template-columns: repeat(2, 1fr);
    }
    .mainpage .weekly {
        grid-template-columns: repeat(1, 1fr);
    }
    .mainpage {
        padding-left: calc((100% - 42.5rem) / 2);
        padding-right: calc((100% - 42.5rem) / 2);
    }
}
@media (max-width: 865px) {
    .mainpage {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
@media (max-width: 668px) {
    .mainpage .gallery {
        grid-template-columns: repeat(1, 1fr);
    }
}
@media (max-width: 520px) {
    .mainpage {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
@media (max-width: 340px) {
    .mainpage {
        padding-left: 0.6rem;
        padding-right: 0.6rem;
    }
}
@media (max-width: 368px) {
    
}

@media (prefers-color-scheme: dark) {
    .slick-dots li button:before {
        color: #EEEEEE;
    }
    .slick-dots li.slick-active button:before {
        color: #FFFDFE;
    }
}