.image-preview-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    /* From https://css.glass */
    /* background: rgba(255, 255, 255, 0.1); */
    background: rgba(165, 165, 165, 0.188); /* 확대 이미지 배경 색 */
    /* box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(23.5px);
    -webkit-backdrop-filter: blur(23.5px);
    z-index: 20000;
}

@media (prefers-color-scheme: dark) {
    .image-preview-box {
        background: #33333330;
    }
}

.image-preview {
    /* 이거 width 수정할 때는 BottomBar.css랑 같이 바꿔야 함 */
    --width: min(80vw, 70vh);
    --width-1percent: calc(var(--width) / 100);

    /* overflow: hidden; */

    position: relative;

    margin-bottom: calc(var(--width-1percent) * 18);
}

.image-preview > .main-img {
    /* width: 25rem;
    height: 25rem; */
    width: var(--width);
    height: var(--width);
    aspect-ratio: 1;
    border: .9px solid #BEBEBE;

    border-radius: calc(var(--width-1percent) * 6.5);
}
@media (prefers-color-scheme: dark) {
    .image-preview > .main-img {
        border: .9px solid #444444;
    }
}

.image-preview > .close {
    position: absolute;
    top: calc(var(--width-1percent) * 6);
    right: calc(var(--width-1percent) * 6);
    color: white;
}
@media (prefers-color-scheme: dark) {
    .image-preview > .close {
        color: black;
    }
}


.image-preview > .close > img {
    width: calc(var(--width-1percent) * 5);
}

.image-preview > .loading {
    position: absolute;
    /* top: 2.5rem;
    left: 2.5rem;
    width: .9rem; */
    top: calc(var(--width-1percent) * 7);
    left: calc(var(--width-1percent) * 7);
    width: calc(var(--width-1percent) * 2.3);
}