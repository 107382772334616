header {
    position: relative;
    box-sizing: border-box;

    width: 100vw;
    height: 4.5rem;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    /* background-color: #FFFDFE; */
    /* From https://css.glass */
    /* background: rgba(255, 255, 255, 0.1); */
    background: #FFFDFEC0;
    /* box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(29px);
    -webkit-backdrop-filter: blur(29px);

    border-bottom: .8px solid #BEBEBE;

    padding-top: 1.4rem;
    padding-bottom: 1.1rem;

    position: fixed;
    top: 0;
    left: 0;

    z-index: 10000;

    font-family: 'Pretendard-Bold';
    font-size: 1.035rem;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    --header-margin: calc((100% - 70rem) / 2);
    gap: 1.5rem;
}
@media (prefers-color-scheme: dark) {
    header {
        background: #2A2A2AC0;
        backdrop-filter: blur(29px);
        -webkit-backdrop-filter: blur(29px);

        border-bottom: .8px solid #444444;
    }
}

header > * {
    color: #333333;
}
@media (prefers-color-scheme: dark) {
    header > * {
        color: white;
    }

}
header .logo {
    height: 2rem;
}
header > .margin {
    flex-grow: 1;
}
header > .navlink {
    text-decoration: none;
    color: black;
}
header > .navlink:hover {
    color: white;
}

header > :nth-child(1) {
    margin-left: var(--header-margin);
}
header > .header-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: var(--header-margin);
}
header > .header-right > div {
    max-width: 6.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

header > .menu {
    right: var(--header-margin);
}

@media (max-width: 1200px) {
    header {
        --header-margin: calc((100% - 45rem) / 2);
        gap: 1rem;
    }
}
@media (max-width: 865px) {
    header {
        --header-margin: 2rem;
        gap: 1rem;
    }
    header > .header-right > div {
        max-width: 6rem;
    }
}
@media (max-width: 520px) {
    header {
        align-items: center;
        --header-margin: 1rem;
        gap: 1rem;
    }
}
@media (max-width: 340px) {
    header {
        --header-margin: 0.6rem;
        gap: 0.6rem;
    }
    header > .header-right > div {
        max-width: 5.5rem;
    }
}

header > .header-right > .logout {
    color: #DD0D75;
    font-size: 0.67rem;
    margin-right: .05rem;
    margin-bottom: 0.1rem;
}

header > .menu {
    position: absolute;
    top: calc(100% + 0.5rem);
    box-sizing: border-box;
    border: .8px solid #BEBEBE;
    border-radius: 0.5rem;
    padding: 0.6rem;
    z-index: 10000;

    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0,0,0,0.03), 0 3px 6px rgba(0,0,0,0.1);
    width: 13rem;
}